import { Controller } from "@hotwired/stimulus";
import * as bootstrap from "bootstrap";

export default class extends Controller {
  static targets = [
    "vendorButton",
    "newVendor",
    "newVendorInput",
    "vendorList",
    "errorsList",
    "poCreationMessage",
    "bomStatus",
    "receiverList",
  ];

  updateStatus(event) {
    // const selected_status = event.target.value;
    // if (selected_status === "confirmed") {
    //   this.element.querySelector("#po_message").classList.remove("d-none");
    // }
    this.bomStatusTarget.requestSubmit();
  }

  showQuatityInput(event) {
    event.preventDefault();
    const item_id = event.target.dataset.itemId;
    this.element
      .querySelector(`#quantity-input-${item_id}`)
      .classList.remove("d-none");
    this.element
      .querySelector(`#quantity-text-${item_id}`)
      .classList.add("d-none");
  }

  showNewVendor(event) {
    if (event.target.value === "Add a new vendor") {
      const vendor_input_target = event.target.dataset.vendorInputId;
      const vendor_input = this.element.querySelector(
        "#" + vendor_input_target,
      );
      vendor_input.classList.toggle("d-none");
    } else {
      this.isAllDropdownSelected();
    }
  }

  toggleBadgeDetails(event) {
    if (event.target.checked) {
      this.showBadgeContainer(event);
    } else {
      this.hideBadgeContainer(event);
    }
  }

  toggleFulfillingMethod(event) {
    const fulfilling_method_container = this.fulfillingMethodContainer(
      event.target.dataset.itemId,
    );
    const delivery_container = this.deliveryContainer(
      event.target.dataset.itemId,
    );
    const pickup_container = this.pickupContainer(event.target.dataset.itemId);

    if (event.target.checked) {
      fulfilling_method_container.classList.remove("text-muted");
      delivery_container.disabled = false;
      pickup_container.disabled = false;
    } else {
      fulfilling_method_container.classList.add("text-muted");
      delivery_container.disabled = true;
      pickup_container.disabled = true;
    }
  }

  checkStaging(event) {
    if (event.target.checked) {
      const staging_check_box_id = event.target.dataset.stagingId;
      this.element.querySelector(`#${staging_check_box_id}`).checked = true;
    }
  }

  orderquantityValidation(event) {
    const max_value = event.target.dataset.maxValue;
    const error_container_id = event.target.dataset.alertId;
    const bill_of_material_id = event.target.dataset.billOfMaterialId;
    const bill_of_material_item_id = event.target.dataset.billOfMaterialItemId;
    const purchase_quantity = event.target.value;
    const minimum_order_adjustment = parseInt(
      event.target.dataset.minimumOrderAdjustment,
    );
    const restock_quantity = parseInt(event.target.dataset.restockQuantity);

    if (parseInt(max_value) < parseInt(purchase_quantity)) {
      this.element
        .querySelector(`#${error_container_id}`)
        .classList.remove("d-none");
      event.target.value =
        parseInt(max_value) + minimum_order_adjustment + restock_quantity;
    } else {
      this.element
        .querySelector(`#${error_container_id}`)
        .classList.add("d-none");

      this.addPurchaseCount(
        bill_of_material_id,
        bill_of_material_item_id,
        purchase_quantity,
      );
    }
  }

  quantityValidation(event) {
    const max_value = event.target.dataset.maxValue;
    const error_container_id = event.target.dataset.alertId;

    if (parseInt(max_value) < parseInt(event.target.value)) {
      this.element
        .querySelector(`#${error_container_id}`)
        .classList.remove("d-none");
      event.target.value = max_value;
    } else {
      this.element
        .querySelector(`#${error_container_id}`)
        .classList.add("d-none");
    }
  }

  addPurchaseCount(
    bill_of_material_id,
    bill_of_material_item_id,
    purchase_quantity,
  ) {
    const purchase_link = this.element.querySelector(
      `#purchase_order_url_${bill_of_material_item_id}`,
    );
    const purchase_url = `/bill_of_materials/${bill_of_material_id}/bill_of_material_items/${bill_of_material_item_id}/update_purchase_quantity?purchase_quantity=${purchase_quantity}`;
    purchase_link.href = purchase_url;

    // Simulate a click on the link
    purchase_link.click();
  }

  addProductPurchaseCount(event) {
    const product_id = event.target.dataset.productId;
    const purchase_link = this.element.querySelector(
      `#product_purchase_order_url_${product_id}`,
    );
    const product_purchase_url = `/products/${product_id}/update_purchase_quantity?purchase_quantity=${event.target.value}`;
    purchase_link.href = product_purchase_url;

    // Simulate a click on the link
    purchase_link.click();
  }

  showRestockOption(event) {
    const form_id = event.target.dataset.alertId;
    const validation_id = event.target.dataset.validationId;

    event.preventDefault();

    const formElement = this.element.querySelector(`#${form_id}`);
    const validationElement = this.element.querySelector(`#${validation_id}`);

    if (formElement) {
      formElement.classList.remove("d-none");
      validationElement.classList.add("d-none"); // Hide the validation container
    }
  }

  hideRestockOption(event) {
    const form_id = event.target.dataset.alertId;

    event.preventDefault();

    const formElement = this.element.querySelector(`#${form_id}`);

    if (formElement) {
      formElement.classList.add("d-none");
    }
  }

  checkQuantityValidation(event) {
    const minimum_order_count = parseInt(event.target.dataset.minimumCount);
    const quantity = parseInt(event.target.value);
    const bill_of_material_item_id = event.target.dataset.billOfMaterialItemId;

    if (quantity % minimum_order_count === 0) {
      this.element
        .querySelector(`#minimum_order_validation_${bill_of_material_item_id}`)
        .classList.add("d-none");
      this.element.querySelector(
        `#add_to_order_${bill_of_material_item_id}`,
      ).disabled = false;
    } else {
      this.element
        .querySelector(`#minimum_order_validation_${bill_of_material_item_id}`)
        .classList.remove("d-none");
      this.element.querySelector(
        `#add_to_order_${bill_of_material_item_id}`,
      ).disabled = true;
    }
  }

  addRetockingCount(event) {
    const bill_of_material_id = event.currentTarget.dataset.billOfMaterial;
    const bill_of_material_item_id =
      event.currentTarget.dataset.billOfMaterialItem;
    const restocking_count_container = this.element.querySelector(
      `#minimum_quantity_input_${bill_of_material_item_id}`,
    ).value;
    const restocking_link = this.element.querySelector(
      `#restocking_url_${bill_of_material_item_id}`,
    );
    const restocking_url = `/bill_of_materials/${bill_of_material_id}/bill_of_material_items/${bill_of_material_item_id}/restock_item?restock_quantity=${restocking_count_container}`;

    restocking_link.href = restocking_url;

    // Simulate a click on the link
    restocking_link.click();
  }

  toggleReceiveComment(event) {
    const ordered_quantity = event.target.dataset.orderedValue;
    const itemId = event.target.dataset.itemId;

    if (parseInt(ordered_quantity) > parseInt(event.target.value)) {
      this.element.querySelector(`#receive_comment_${itemId}`).disabled = false;
    } else {
      this.element.querySelector(`#receive_comment_${itemId}`).disabled = true;
    }
  }

  // Function will be invoked when stock is adjusted to update available count value in UI
  updateAvailableCount(event) {
    const item_id = event.target.dataset.itemId;
    const adjusted_quantity = event.target.value;
    const available_count = this.availableCount(item_id);
    const required_count = this.requiredCount(item_id).innerText;
    const available_container = this.availableContainer(item_id);
    const insufficient_stock_alert = this.insufficientStockAlert(item_id);
    const staging_button = this.stagingButton(item_id);
    const original_available_count = this.originalAvailableCount(item_id);
    let release_count;

    if (adjusted_quantity != "") {
      release_count =
        parseInt(available_count.innerText) -
        parseInt(original_available_count.innerText);
      original_available_count.innerHTML = adjusted_quantity;
      available_count.innerHTML =
        parseInt(adjusted_quantity) + Math.max(0, release_count);

      if (parseInt(available_count.innerText) < parseInt(required_count)) {
        available_container.classList.add("border-danger");
      } else {
        available_container.classList.remove("border-danger");
      }

      if (parseInt(available_count.innerText) == 0) {
        insufficient_stock_alert.classList.remove("d-none");
        staging_button.disabled = true;
      } else {
        insufficient_stock_alert.classList.add("d-none");
        staging_button.disabled = false;
      }
    }
  }

  // Function will be invoked when stocks are released to update available count, remainging count value in UI
  updateStockAfterReleasing(event) {
    const item_id = event.target.dataset.itemId;
    const parent_item_id = event.target.dataset.parentItemId;
    const release_count = event.target.value;
    const old_release_count = this.oldReleaseCount(item_id).value;
    const available_count = this.availableCount(parent_item_id);
    const required_count = this.requiredCount(parent_item_id).innerText;
    const available_container = this.availableContainer(parent_item_id);
    const target_quantity = this.targetQuantityCount(item_id);
    const remaining_count = this.remainingCount(item_id);
    const release_item_original_quantity =
      this.releaseItemOriginalQuantity(item_id);
    const staging_button = this.stagingButton(parent_item_id);
    const insufficient_stock_alert =
      this.insufficientStockAlert(parent_item_id);
    let changed_count;

    if (parseInt(release_count) > parseInt(target_quantity.innerText)) {
      remaining_count.innerHTML = parseInt(target_quantity.innerText);
      changed_count = parseInt(target_quantity.innerText) - old_release_count;
      available_count.innerHTML =
        parseInt(available_count.innerText) + parseInt(changed_count);
      event.target.value = parseInt(target_quantity.innerText);
    } else {
      changed_count = release_count - old_release_count;
      remaining_count.innerHTML =
        parseInt(release_item_original_quantity.innerText) -
        (parseInt(target_quantity.innerText) - parseInt(release_count));
      available_count.innerHTML =
        parseInt(available_count.innerText) + parseInt(changed_count);
    }

    // Update old count with new value
    this.oldReleaseCount(item_id).value = event.target.value;

    if (parseInt(available_count.innerText) < parseInt(required_count)) {
      available_container.classList.add("border-danger");
    } else {
      available_container.classList.remove("border-danger");
    }

    if (parseInt(available_count.innerText) == 0) {
      staging_button.disabled = true;
      insufficient_stock_alert.classList.remove("d-none");
    } else {
      staging_button.disabled = false;
      insufficient_stock_alert.classList.add("d-none");
    }
  }

  showBadgeContainer(event) {
    const badge_containers = this.badgeContainer(event.target.dataset.itemId);

    badge_containers.forEach((container) => {
      if (container.classList.contains("inverse-display")) {
        container.classList.add("d-none");
      } else {
        container.classList.remove("d-none");
      }
    });
  }

  hideBadgeContainer(event) {
    const badge_containers = this.badgeContainer(event.target.dataset.itemId);
    const isChecked = this.itemCheckBox(event.target.dataset.itemId).checked;

    if (!isChecked) {
      badge_containers.forEach((container) => {
        if (container.classList.contains("inverse-display")) {
          container.classList.remove("d-none");
        } else {
          container.classList.add("d-none");
        }
      });
    }
  }

  badgeContainer(id) {
    return this.element.querySelectorAll(`.badge-details-${id}`);
  }

  remainingCount(id) {
    return this.element.querySelector(`#remaining_text_${id}`);
  }

  oldReleaseCount(id) {
    return this.element.querySelector(`#old_release_count_${id}`);
  }

  targetQuantityCount(id) {
    return this.element.querySelector(`#target_quantity_text_${id}`);
  }

  originalAvailableCount(id) {
    return this.element.querySelector(`#original_available_text_${id}`);
  }

  releaseItemOriginalQuantity(id) {
    return this.element.querySelector(`#release_item_original_quantity_${id}`);
  }

  availableCount(id) {
    return this.element.querySelector(`#available_text_${id}`);
  }

  availableContainer(id) {
    return this.element.querySelector(`#available_label_${id}`);
  }

  insufficientStockAlert(id) {
    return this.element.querySelector(`#insufficient_stock_alert_${id}`);
  }

  stagingButton(id) {
    return this.element.querySelector(`#staging_button_${id}`);
  }

  requiredCount(id) {
    return this.element.querySelector(`#required_text_${id}`);
  }

  fulfillingMethodContainer(id) {
    return this.element.querySelector(`#item_${id}_fulfilling_method`);
  }

  deliveryContainer(id) {
    return this.element.querySelector(`.item_${id}_delivery_method`);
  }

  pickupContainer(id) {
    return this.element.querySelector(`.item_${id}_pickup_method`);
  }

  itemCheckBox(id) {
    return this.element.querySelector(`#item-checkbox-${id}`);
  }

  async addNewVendor(event) {
    const item_data_value = event.currentTarget.dataset.itemId;
    const item_id = item_data_value.split("_")[1];
    const product_id = "product_" + item_id;
    const new_vendor_div = this.element.querySelector("#" + product_id);
    const new_vendor_input = new_vendor_div.querySelector(
      'input[name="new_vendor"]',
    ).value;

    try {
      const response = await fetch(`/new_vendor/${new_vendor_input}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (response.ok) {
        this.element
          .querySelectorAll(".vendor_dropdowm")
          .forEach((select_tag) => {
            const newOption = document.createElement("option");
            newOption.textContent = data.new_vendor;
            newOption.value = data.new_vendor;

            // Find the "Select vendor" option
            const selectVendorOption = select_tag.querySelector(
              'option[value="Select vendor"]',
            );

            if (selectVendorOption) {
              select_tag.insertBefore(
                newOption,
                selectVendorOption.nextSibling,
              );
            } else {
              select_tag.appendChild(newOption);
            }

            if (select_tag.dataset.vendorInputId === product_id) {
              newOption.selected = true;
            }
          });

        new_vendor_div.classList.add("d-none");
        this.errorsListTarget.classList.add("d-none");
        this.isAllDropdownSelected();
      } else {
        if (data.errors) {
          const error_div = new_vendor_div.querySelector(".vendor_error");
          this.showErrors(data.errors, error_div);
          this.isAllDropdownSelected();
        } else {
          console.error("Request failed:", response);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  showErrors(errors, error_div) {
    error_div.textContent = "";

    const errorElements = Object.entries(errors)
      .map(([attribute, attributeErrors]) =>
        attributeErrors.map((errorMessage) => `${attribute} ${errorMessage}`),
      )
      .flat()
      .map((errorMessage) => {
        const errorMessageElement = document.createElement("div");
        errorMessageElement.textContent = errorMessage;
        return errorMessageElement;
      });

    error_div.append(...errorElements);
    error_div.classList.remove("d-none");
  }

  isAllDropdownSelected() {
    let all_selected = true;
    this.element.querySelectorAll(".vendor_dropdowm").forEach((select_tag) => {
      if (
        select_tag.value === "Select vendor" ||
        select_tag.value === "Add a new vendor"
      ) {
        all_selected = false;
      }
    });

    if (all_selected) {
      this.element
        .querySelector("#continue_button")
        .classList.remove("disabled");
    } else {
      this.element.querySelector("#continue_button").classList.add("disabled");
    }
  }

  // Functions to fetch which items are selected

  // Returns selected items Id
  fetchSelectedItems(event) {
    event.preventDefault();

    const current_target = event.target;
    const items_dom = document.querySelectorAll(".item-checkbox");
    const hasDatasetAttributes =
      current_target.dataset.url && current_target.dataset.originalId;

    let closest_a_or_button = current_target;
    if (!hasDatasetAttributes) {
      closest_a_or_button = current_target.closest(
        "a[data-url][data-original-id]",
      );
      if (!closest_a_or_button) {
        closest_a_or_button = current_target.closest(
          "button[data-url][data-original-id]",
        );
      }
    }

    const url_value = closest_a_or_button
      ? closest_a_or_button.dataset.url
      : "";
    const original_id = closest_a_or_button
      ? closest_a_or_button.dataset.originalId
      : "";
    const link_tag = this.originalLink(original_id);
    const item_ids = [];

    items_dom.forEach(function (currentItem) {
      if (currentItem.checked) {
        item_ids.push(currentItem.dataset.itemId);
      }
    });

    const url_with_params = `${url_value}?item_ids=${item_ids.join(",")}`;

    link_tag.href = url_with_params;
    link_tag.click();
  }

  originalLink(id) {
    return this.element.querySelector(`#${id}`);
  }

  // Function to hide / show multiple item content in one popup
  toggleModalContent(event) {
    const current_item_id = event.target.dataset.currentItemId;
    const current_item_container = this.itemContainer(current_item_id);
    const new_item_id = event.target.dataset.newItemId;
    const new_item_container = this.itemContainer(new_item_id);

    current_item_container.classList.add("d-none");
    new_item_container.classList.remove("d-none");
  }

  itemContainer(id) {
    return this.element.querySelector(`#item_content_${id}`);
  }

  // Function to hide / show multiple item content in one popup
  toggleReleaseContent(event) {
    const current_item_id = event.target.dataset.currentItemId;
    const release_item_id = event.target.dataset.releaseItemId;
    const current_item_container = this.element.querySelector(
      `#staging_modal_content_${current_item_id}`,
    );
    const release_item_container = this.element.querySelector(
      `#release_item_${release_item_id}`,
    );

    current_item_container.classList.add("d-none");
    release_item_container.classList.remove("d-none");
  }

  itemContainer(id) {
    return this.element.querySelector(`#item_content_${id}`);
  }

  updateQuantityInUrl(event) {
    event.preventDefault();

    const quantity_id = event.target.dataset.quantityId;
    const target_url = event.target.dataset.url;
    const target_id = event.target.dataset.id;
    const link_tag = this.element.querySelector(`#${target_id}`);
    const quantity_value = this.element.querySelector(`#${quantity_id}`).value;
    const url_with_params = `${target_url}?quantity=${quantity_value}`;

    link_tag.href = url_with_params;
    link_tag.click();
  }

  submitForm(event) {
    const form_id = event.target.dataset.formId;
    const form_container = this.element.querySelector(`#${form_id}`);
    const item_id = event.target.dataset.itemId;
    const item_container = this.element.querySelector(`#${item_id}`);
    const order_count_containers = document.querySelectorAll(
      ".need_to_order_item_count",
    );

    form_container.submit();

    item_container.classList.add("d-none");
    event.target.classList.add("d-none");

    order_count_containers.forEach((order_count_container) => {
      let count = parseInt(
        order_count_container.innerText.replace(/[()]/g, ""),
      );
      order_count_container.innerHTML = `(${count + 1})`;
    });
  }

  processOrder(event) {
    event.preventDefault();
    const form_id = event.target.dataset.formId;
    const form_container = this.element.querySelector(`#${form_id}`);

    form_container.submit();
  }

  // Show / Hide email option if user chooses to create new email
  toggleAddNewEmail(event) {
    const selected_value = event.target.value;
    const bom_id = event.target.dataset.bomId;
    const email_container = this.targetContainer(event.target.dataset.emailId);

    if (selected_value == "Add new email") {
      email_container.classList.remove("d-none");
    } else {
      email_container.classList.add("d-none");
    }
  }

  // Sends ajax call to add new values in database and update the dropdown
  async addNewvalue(event) {
    event.preventDefault();
    const target_input_id = event.target.dataset.targetInput;
    const target_input_container = this.targetContainer(target_input_id);
    const target_select_id = event.target.dataset.targetSelect;
    const target_select_container = this.targetContainer(target_select_id);
    const new_value_container_id = event.target.dataset.newContainer;
    const new_value_container = this.targetContainer(new_value_container_id);
    const error_container_id = event.target.dataset.errorId;
    const error_container = this.targetContainer(error_container_id);
    const newEmail = target_input_container.value;

    // Check if the email already exists in the dropdown
    if (target_select_container.querySelector(`option[value="${newEmail}"]`)) {
      error_container.classList.remove("d-none");
      error_container.innerText =
        "Cannot add as this email already present in the dropdown";
      return;
    } else {
      error_container.classList.add("d-none");
    }

    try {
      const response = await fetch(`/create_email?email=${newEmail}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (response.ok) {
        // Create a new option element for the newly created tag
        const newOption = document.createElement("option");
        newOption.value = data.new_email;
        newOption.textContent = data.new_email;
        target_select_container.prepend(newOption);

        // Set the new option as checked
        newOption.selected = true;

        // Hide new email form
        new_value_container.classList.add("d-none");
      } else {
        if (data.errors) {
          this.showErrors(data.errors, error_container_id);
        } else {
          console.error("Request failed:", response);
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async addToEmailNewvalue(event) {
    event.preventDefault();
    const target_input_id = event.target.dataset.targetInput;
    const target_input_container = this.targetContainer(target_input_id);
    const target_select_id = event.target.dataset.targetSelect;
    const target_select_container = this.targetContainer(target_select_id);
    const target_cc_select_id = event.target.dataset.ccSelect;
    const target_cc_select_container =
      this.targetContainer(target_cc_select_id);
    const new_value_container_id = event.target.dataset.newContainer;
    const new_value_container = this.targetContainer(new_value_container_id);
    const error_container_id = event.target.dataset.errorId;
    const error_container = this.targetContainer(error_container_id);
    const vendor_id = event.target.dataset.vendorId;
    const newEmail = target_input_container.value;

    if (target_select_container.querySelector(`option[value="${newEmail}"]`)) {
      error_container.classList.remove("d-none");
      error_container.innerText =
        "Cannot add as this email already present in the dropdown";
      return;
    } else {
      error_container.classList.add("d-none");
      const newOption = document.createElement("option");
      newOption.value = newEmail;
      newOption.textContent = newEmail;
      const newCCOption = document.createElement("option");
      newCCOption.value = newEmail;
      newCCOption.textContent = newEmail;
      target_select_container.prepend(newOption);
      target_cc_select_container.prepend(newCCOption);
      newOption.selected = true;
      new_value_container.classList.add("d-none");
    }
  }

  async addCCEmailNewvalue(event) {
    event.preventDefault();
    const target_input_id = event.target.dataset.targetInput;
    const target_input_container = this.targetContainer(target_input_id);
    // const target_select_id = event.target.dataset.targetSelect;
    // const target_select_container = this.targetContainer(target_select_id);
    const target_cc_select_id = event.target.dataset.ccSelect;
    const target_cc_select_container =
      this.targetContainer(target_cc_select_id);
    const new_value_container_id = event.target.dataset.newContainer;
    const new_value_container = this.targetContainer(new_value_container_id);
    const cc_error_container = this.targetContainer(
      event.target.dataset.errorId,
    );
    const newEmail = target_input_container.value;

    // Check if the email already exists in the CC dropdown
    if (
      target_cc_select_container.querySelector(`option[value="${newEmail}"]`)
    ) {
      cc_error_container.classList.remove("d-none");
      cc_error_container.innerText =
        "Cannot add as this email already present in the dropdown";
      return;
    } else {
      cc_error_container.classList.add("d-none");
    }

    const newCCOption = document.createElement("option");
    newCCOption.value = newEmail;
    newCCOption.textContent = newEmail;

    target_cc_select_container.prepend(newCCOption);
    newCCOption.selected = true;
    new_value_container.classList.add("d-none");
  }

  async addCC(event) {
    event.preventDefault();
    const target_select_id = event.target.dataset.targetSelect;
    const target_select_container = this.targetContainer(target_select_id);
    const cc_container_id = event.target.dataset.ccDiv;
    const cc_container = this.targetContainer(cc_container_id);
    const cc_emails_list = cc_container.innerText
      .split(",")
      .filter((item) => item.trim() !== "");
    const hidden_cc_container_id = "hidden_" + cc_container_id;
    const hidden_cc_container = this.targetContainer(hidden_cc_container_id);
    const cc_error_container = this.targetContainer(
      event.target.dataset.errorId,
    );
    // const selectedReceiver = document.querySelector('select[name="receiver"]').value;
    const selectedReceiver = this.receiverListTarget.value;
    const targetValue = target_select_container.value.trim();
    const emailWithoutSpace = cc_emails_list.map((email) => email.trim());

    if (selectedReceiver === targetValue) {
      cc_error_container.classList.remove("d-none");
      cc_error_container.innerText =
        "Cannot add as this email is selected as the receiver email.";
    } else if (emailWithoutSpace.includes(targetValue)) {
      cc_error_container.classList.remove("d-none");
      cc_error_container.innerText =
        "Cannot add as this email has already been added as CC.";
    } else {
      cc_error_container.classList.add("d-none");
      if (cc_emails_list.length === 0) {
        cc_container.innerHTML = targetValue;
      } else {
        cc_container.innerHTML += `, ${targetValue}`;
      }
    }
    hidden_cc_container.value = cc_container.innerHTML;
  }

  targetContainer(id) {
    return this.element.querySelector(`#${id}`);
  }

  showErrors(errors, id) {
    const error_container = this.targetContainer(id);
    error_container.textContent = "";

    const errorElements = Object.entries(errors)
      .map(([attribute, attributeErrors]) =>
        attributeErrors.map((errorMessage) => `${attribute} ${errorMessage}`),
      )
      .flat()
      .map((errorMessage) => {
        const errorMessageElement = document.createElement("div");
        errorMessageElement.textContent = errorMessage;
        return errorMessageElement;
      });

    error_container.append(...errorElements);
    error_container.classList.remove("d-none");
  }

  toggleUndoActionCheckbox(event) {
    // const new_status_hidden_field = this.element.querySelector(`#${event.target.dataset.newStatusHiddenField}`)
    // const new_status = event.target.dataset.newStatus
    const target_class = event.target.dataset.targetClass;
    const target_checkbox = event.target.dataset.targetCheckbox;

    let all_action_checkboxes = this.selectTargetCheckboxes(target_checkbox);
    let checkboxes_to_select = this.selectTargetCheckboxes(target_class);

    if (event.target.checked) {
      all_action_checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });

      checkboxes_to_select.forEach((checkbox) => {
        checkbox.checked = true;
      });

      // Set new status value
      // new_status_hidden_field.value = new_status
    } else {
      all_action_checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  }

  selectTargetCheckboxes(class_value) {
    return this.element.querySelectorAll(`.${class_value}`);
  }

  AddVendor(event) {
    const add_button = event.target.dataset.addButton;
    const hidden_vendor_id = event.target.dataset.hiddenVendor;

    if (event.target.value != "") {
      this.element.querySelector(`#${add_button}`).disabled = false;
      this.element.querySelector(`#${hidden_vendor_id}`).value =
        event.target.value;
    } else {
      this.element.querySelector(`#${add_button}`).disabled = true;
      this.element.querySelector(`#${hidden_vendor_id}`).value = nil;
    }
  }

  shouldHideTable(event) {
    const table_id = event.target.dataset.tableId;
    const table_obj = this.element.querySelector(`#${table_id}`);
    const items = table_obj.querySelectorAll(".vendor-items:not(.d-none)");
    const minimun_length = event.target.dataset.minimumLength;

    if (items.length == parseInt(minimun_length)) {
      table_obj.classList.add("d-none");
    }
  }

  skipItem(event) {
    const item_id = event.target.dataset.currentItemId;
    const action_type = event.target.dataset.actionType;
    const skip_alert = this.element.querySelector(`#skip_alert_${item_id}`);
    const skip_button = this.element.querySelector(`#skip_button_${item_id}`);
    const un_skip_button = this.element.querySelector(
      `#unskip_button_${item_id}`,
    );
    const fulfilled_quantity = this.element.querySelector(
      `#fulfilled_quantity_${item_id}`,
    );

    skip_alert.classList.toggle("d-none");
    skip_button.classList.toggle("d-none");
    un_skip_button.classList.toggle("d-none");
    if (action_type == "skip") {
      fulfilled_quantity.value = 0;
      fulfilled_quantity.disabled = true;
    } else {
      fulfilled_quantity.disabled = false;
    }
  }

  isAllDropdownSelected() {
    const vendor_dropdowns = this.element.querySelectorAll(".vendor-select");
    const vendor_submit = this.element.querySelector("#missing_vendor_button");

    // Assume all dropdowns are selected initially
    let allDropdownsSelected = true;

    vendor_dropdowns.forEach((dropdown) => {
      // Check if the selected value is null or empty
      if (dropdown.value == "") {
        allDropdownsSelected = false;
      }
    });

    if (allDropdownsSelected) {
      vendor_submit.disabled = false;
    } else {
      vendor_submit.disabled = true;
    }
  }

  toggleMethodDescription(event) {
    const target_id = event.target.dataset.targetId;
    const should_check_validation = event.target.dataset.checkValidation;
    const method_descriptions =
      this.element.querySelectorAll(".return_methods");

    method_descriptions.forEach((description_container) => {
      description_container.classList.toggle(
        "d-none",
        description_container.id !== target_id,
      );
    });

    this.returnQuantityValidation(event);
  }

  returnQuantityValidation(event) {
    const item_id = event.target.dataset.itemId;
    const quantity = parseInt(
      this.element.querySelector("#return_quantity_input").value,
    );
    const minimum_order_count = parseInt(event.target.dataset.minimumOrder);
    const product_available_stock = parseInt(
      event.target.dataset.productAvailableStock,
    );
    const receive_count = parseInt(event.target.dataset.receiveCountForBom);
    const receive_count_from_bom = Math.min(receive_count, quantity);
    const count_from_inventory = quantity - receive_count_from_bom;
    const max_value = parseInt(event.target.dataset.maxValue);
    const error_container_id = event.target.dataset.alertId;
    const restocking_method_button = this.element.querySelector("#restock");

    if (max_value < quantity) {
      this.element
        .querySelector(`#${error_container_id}`)
        .classList.remove("d-none");
      this.element.querySelector("#return_quantity_input").value = max_value;
      return;
    }

    const error_messages = {
      minimum_order: `#minimum_order_return_validation_${item_id}`,
      minimum_inventory: `#minimum_inventory_validation_${item_id}`,
    };

    if (
      minimum_order_count !== 0 &&
      quantity % minimum_order_count !== 0 &&
      !restocking_method_button.checked
    ) {
      this.element
        .querySelector(error_messages.minimum_order)
        .classList.remove("d-none");
      this.element
        .querySelector(error_messages.minimum_inventory)
        .classList.add("d-none");
      this.element.querySelector("#return_button").disabled = true;
    } else if (
      count_from_inventory > product_available_stock &&
      !restocking_method_button.checked
    ) {
      this.element
        .querySelector(error_messages.minimum_inventory)
        .classList.remove("d-none");
      this.element
        .querySelector(error_messages.minimum_order)
        .classList.add("d-none");
      this.element.querySelector("#return_button").disabled = true;
    } else {
      this.element
        .querySelector(error_messages.minimum_order)
        .classList.add("d-none");
      this.element
        .querySelector(error_messages.minimum_inventory)
        .classList.add("d-none");
      this.element.querySelector("#return_button").disabled = false;
    }
  }
}
